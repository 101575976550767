import { Workflow, WorkflowProcess } from 'protos/pb/v1alpha1/orbot_workflow';
import { ActionObject, WorkflowObject } from '../types';
import {
  Action,
  ActionParamValue,
  WorkflowVariable,
} from 'protos/pb/v1alpha1/orbot_action';

export function getActions(
  workflow?: WorkflowObject | null,
  processId?: string,
): Action[] | undefined {
  if (workflow?.processes && workflow.processes.length > 0) {
    // Default to first process if no process id is provided
    let process: WorkflowProcess | undefined = workflow.processes[0];
    if (processId) {
      process = workflow.processes.find((process) => process.id === processId);
    }
    return process?.actions;
  } else {
    throw new Error('No process found in workflow');
  }
}

export const allowAddingElseBlock = (action: Action): boolean => {
  // if there is any conditionAction that has thenActions but no elseActions, we will allow the user to add else block
  if (
    (action?.condition?.thenActions ?? []).length > 0 &&
    (action?.condition?.elseActions ?? []).length === 0
  ) {
    return true;
  }
  return false;
};

export function getActionById(
  id: string,
  actions?: Action[],
): Action | undefined {
  if (!actions || actions.length === 0) {
    return undefined;
  }

  for (const action of actions) {
    if (action.id === id) {
      return action;
    }
    if (action.prerequisites) {
      const prerequisite = getActionById(
        id,
        action.prerequisites.map((prerequisite) => prerequisite.action!),
      );
      if (prerequisite) {
        return prerequisite;
      }
    }
    if (action.condition) {
      const trueBranch = getActionById(id, action.condition.thenActions ?? []);
      if (trueBranch) {
        return trueBranch;
      }
      const falseBranch = getActionById(id, action.condition.elseActions ?? []);
      if (falseBranch) {
        return falseBranch;
      }
    } else if (action.foreach) {
      const loop = getActionById(id, action.foreach.loopActions ?? []);
      if (loop) {
        return loop;
      }
    } else if (action.block) {
      const block = getActionById(id, action.block.actions ?? []);
      if (block) {
        return block;
      }
    }
  }
}

export function getWorkflowVariables(
  workflow: Workflow | null,
  processId?: string,
): WorkflowVariable[] {
  const workflowVariables: WorkflowVariable[] = [];
  if (!workflow) {
    return workflowVariables;
  }

  traverseActionsRecursively(
    getActions(workflow, processId) || [],
    (action) => {
      getParams(action).forEach((param) => {
        if (param?.envValue) {
          workflowVariables.push({ key: param.envValue, value: undefined });
        }
      });
    },
  );
  return workflowVariables;
}

export function traverseActionsRecursively(
  actions: Action[] | undefined,
  callback: (action: Action) => void,
): void {
  if (!actions || !actions.length) return;
  actions.forEach((action) => {
    callback(action);
    if (action.prerequisites) {
      traverseActionsRecursively(
        action.prerequisites.map((prerequisite) => prerequisite.action!),
        callback,
      );
    }
    if (action.block) {
      traverseActionsRecursively(action.block.actions, callback);
    } else if (action.condition) {
      traverseActionsRecursively(action.condition.thenActions, callback);
      traverseActionsRecursively(action.condition?.elseActions, callback);
    } else if (action.foreach) {
      traverseActionsRecursively(action.foreach.loopActions, callback);
    }
  });
}

function getParams(action: Action): ActionParamValue[] {
  if (action.click?.locator) {
    return [action.click.locator];
  }
  if (action.getElement?.elementLocator) {
    return [action.getElement.elementLocator];
  }
  if (action.hover?.locator) {
    return [action.hover.locator];
  }
  if (action.condition?.condition) {
    return [action.condition.condition];
  }
  if (action.foreach?.items) {
    return [action.foreach.items];
  }
  if (action.createTask?.workflowVariables) {
    return action.createTask.workflowVariables;
  }
  if (action.customSmartAction?.inputs) {
    return Object.values(action.customSmartAction.inputs);
  }
  if (action.detectDuplicateLineItems?.source) {
    return [action.detectDuplicateLineItems.source];
  }
  if (action.extractFields?.document) {
    return [action.extractFields.document];
  }
  if (action.flagKeywords?.source) {
    return [action.flagKeywords.source];
  }
  if (action.generateText?.inputs) {
    return action.generateText.inputs;
  }
  if (action.goto?.url) {
    return [action.goto.url];
  }
  if (action.jsFunction?.params) {
    return action.jsFunction.params;
  }
  if (action.setValue?.fieldLocator) {
    return [action.setValue.fieldLocator!, action.setValue.fieldValue!];
  }
  if (action.validate?.source) {
    return [action.validate.source!, action.validate.target!];
  }

  return [];
}

export function getCreateTaskActionUUIDs(
  workflow: Workflow,
  processId?: string,
): string[] {
  const uuids: string[] = [];
  traverseActionsRecursively(getActions(workflow, processId), (action) => {
    if (action.createTask) {
      if (action.id) {
        uuids.push(action.id);
      }
    }
  });
  return uuids;
}

export function addActionAfter(
  newAction: ActionObject,
  id: string,
  actions?: Action[],
): boolean {
  if (!actions || actions.length === 0) {
    return false;
  }
  for (const [index, action] of actions.entries()) {
    if (action.id === id) {
      actions.splice(index + 1, 0, newAction);
      return true;
    }
    if (action.condition) {
      if (
        addActionAfter(newAction, id, action.condition.thenActions ?? []) ||
        addActionAfter(newAction, id, action.condition.elseActions ?? [])
      ) {
        return true;
      }
    }
    if (action.foreach) {
      if (addActionAfter(newAction, id, action.foreach.loopActions ?? [])) {
        return true;
      }
    }
  }
  return false;
}

export function removeAction(
  uuid: string,
  actions: Action[],
): Action | undefined {
  if (!actions || actions.length === 0) {
    return undefined;
  }

  for (const [index, action] of actions.entries()) {
    if (action.id === uuid) {
      actions.splice(index, 1);
      return action;
    }
    if (action.condition) {
      const removedActionGroup =
        removeAction(uuid, action.condition.thenActions ?? []) ??
        removeAction(uuid, action.condition.elseActions ?? []);
      if (removedActionGroup) {
        return removedActionGroup;
      }
    }
    if (action.foreach) {
      const removedAction = removeAction(
        uuid,
        action.foreach.loopActions ?? [],
      );
      if (removedAction) {
        return removedAction;
      }
    }
  }
}
