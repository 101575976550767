import React, { useState } from 'react';
import { Box, Typography, Paper, styled } from '@mui/material';
import { OrbyTabs } from 'orby-ui/src';
import { smartActions } from './smartActionRequest';
import { ItemDocument, SmartAction } from 'protos/pb/v1alpha1/actionprocessing';

// Styled Components
const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '20px',
  paddingLeft: '27px',
  paddingRight: '28px',
  paddingBottom: '20px',
  alignItems: 'flex-start',
  width: '100%',
}));

const TabsContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
}));

const TabContent = styled(Box)(() => ({
  marginTop: '20px',
  width: '100%',
}));

const StyledPaper = styled(Paper)(() => ({
  padding: '16px',
  marginTop: '16px',
}));

const IframeContainer = styled(Box)(() => ({
  width: '100%',
  height: '400px',
  border: '1px solid #ccc',
  marginTop: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#f9f9f9',
}));

const InvoiceIframe = styled('iframe')({
  width: '100%',
  height: '100%',
  border: 'none',
});

interface Props {
  data: SmartAction;
}

const HITLSystemReference: React.FC<Props> = ({ data }) => {
  const [selectedTab, setSelectedTab] = useState(1);

  const documentData = data ? data : smartActions;

  const invoicePdf: ItemDocument[] =
    documentData['reconcileLineItems']?.target?.documents || [];

  const documentUrl = invoicePdf[0]?.document?.gcsUri;

  const tabs = [
    {
      id: 'caaps-form',
      label: 'CAAPS form',
      children: (
        <StyledPaper elevation={3}>
          <Typography variant='h6'>CAAPS Form Details</Typography>
          <Typography>
            <strong>Form ID:</strong> CAAPS-12345
            <br />
            <strong>Submitted By:</strong> John Doe
            <br />
            <strong>Date:</strong> 2024-09-02
            <br />
            <strong>Description:</strong> This is a mock CAAPS form content with
            various details.
          </Typography>
          <Typography>
            <strong>Field 1:</strong> Sample Value 1<br />
            <strong>Field 2:</strong> Sample Value 2<br />
            <strong>Field 3:</strong> Sample Value 3
          </Typography>
        </StyledPaper>
      ),
    },
    {
      id: 'invoice-pdf',
      label: 'Invoice PDF',
      children: (
        <IframeContainer>
          <InvoiceIframe src={documentUrl} title='Invoice PDF Preview' />
        </IframeContainer>
      ),
    },
    {
      id: 'jde',
      label: 'JDE1',
      children: (
        <StyledPaper elevation={3}>
          <Typography variant='h6'>JDE1 System Details</Typography>
          <Typography>
            <strong>System Name:</strong> JDE1
            <br />
            <strong>Status:</strong> Active
            <br />
            <strong>Last Updated:</strong> 2024-09-02
            <br />
            <strong>Description:</strong> This is a mock detail of the JDE1
            system.
          </Typography>
        </StyledPaper>
      ),
    },
  ];

  return (
    <Container id='system-reference'>
      {/* Tabs */}
      <TabsContainer>
        <OrbyTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={tabs}
        />
      </TabsContainer>

      {/* Render the selected tab's content */}
      <TabContent>{tabs[selectedTab]?.children}</TabContent>
    </Container>
  );
};

export default HITLSystemReference;
