import { Box, styled, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import { Workflow } from 'protos/pb/v1alpha1/orbot_workflow';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserFiltersAction } from '../../../redux/actions/filter_options.action';
import { selectedOrgInfoSelector } from '../../../redux/selectors/user.selectors';
import { OrbyButton, OrbyHeaderLabel, OrbyTable } from 'orby-ui/src';
import {
  DRAWER_WIDTH_COLLAPSED,
  DRAWER_WIDTH_EXPANDED,
} from '../../../utils/constants';
import {
  getScheduleDateRangeCell,
  getScheduleDeleteCell,
  getScheduleEditCell,
  getScheduleRecurrenceCell,
  getScheduleTimeCell,
} from './ui-table-helper';
import { Schedule } from 'protos/pb/v1alpha1/schedule';
import { DEFAULT_ROWS_PER_PAGE } from 'orby-ui/src/components/table/table-utils';

interface IWorkflowProps {
  workflow: Workflow | null;
  sideDrawerCollapse: boolean;
}

const Title = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '28px',
  color: grey[900],
}));

const ScheduleWorkflow: React.FC<IWorkflowProps> = ({ sideDrawerCollapse }) => {
  const dispatch = useDispatch();
  // Getting the org id
  const orgInfo = useSelector(selectedOrgInfoSelector)!;

  const [page] = useState(0);
  const [rowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  useEffect(() => {
    dispatch(getUserFiltersAction(orgInfo.orgResourceName as string));
  }, [orgInfo.orgDisplayName]);

  /**
   * GET WIDTH OF THE TABLE
   */
  const getTableWidth = () => {
    const sideNavWidth = sideDrawerCollapse
      ? DRAWER_WIDTH_COLLAPSED
      : DRAWER_WIDTH_EXPANDED;
    const PADDING = 48 * 2;

    return window.innerWidth - sideNavWidth - PADDING;
  };

  const schedules: Schedule[] = [
    {
      id: '1',
      orgId: '1',
      workflowId: '1',
      startTime: new Date(),
      endTime: new Date(),
      config: {
        schedulePattern: {
          runTimes: [
            {
              hours: 12,
              minutes: 0,
              seconds: 0,
            },
            {
              hours: 13,
              minutes: 0,
              seconds: 0,
            },
          ],
          recurrencePattern: {
            dailyRecurrence: {},
          },
        },
        interval: 1,
      },
    },
    {
      id: '2',
      orgId: '1',
      workflowId: '1',
      startTime: new Date(),
      endTime: new Date(),
      config: {
        schedulePattern: {
          runTimes: [
            {
              hours: 12,
              minutes: 0,
              seconds: 0,
            },
            {
              hours: 13,
              minutes: 0,
              seconds: 0,
            },
          ],
          recurrencePattern: {
            dailyRecurrence: {},
          },
        },
        interval: 1,
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 4,
        }}
      >
        <Title>Workflow schedule</Title>
        <OrbyButton
          onClick={() => null}
          variant='primary-outline'
          size='small'
          label='Add'
          ariaLabel='Add'
          sx={{
            padding: '8px 39px',
          }}
        />
      </Box>
      <Box display='flex' flexDirection='column' mt={2} gap={4}>
        <OrbyTable
          noDataMessage={'There are no workflow schedules.'}
          tableId='workflow-api-automation-table'
          showCheckBox={false}
          isCheckBoxDisabled={true}
          cursor='pointer'
          isDataLoading={false}
          tableWidth={getTableWidth()}
          /**
           * PAGINATION
           */
          pagination={{
            rowsPerPage,
            currentPage: page,
            totalRows: 10,
            setCurrentPage: (pageNumber: number) => pageNumber,
            setRowsPerPage: (rows: number) => rows,
          }}
          /**
           * TABLE HEADER ROWS
           */
          headerRows={[
            <OrbyHeaderLabel
              key={'workflow-header-start-end-date'}
              label='Start - End date'
            />,
            <OrbyHeaderLabel key={'workflow-header-repeat'} label='Repeat' />,
            <OrbyHeaderLabel key={'workflow-header-time'} label='Time' />,
            <OrbyHeaderLabel key={'workflow-header-delete'} label='' />,
            <OrbyHeaderLabel key={'workflow-header-edit'} label='' />,
          ]}
          colSx={[
            {
              width: '267px',
              maxWidth: '303px',
              minWidth: '267px',
              padding: '12px 24px',
            },
            {
              width: '409px',
              maxWidth: '493px',
              minWidth: '409px',
              padding: '12px 24px',
            },
            {
              width: '200px',
              maxWidth: '248px',
              minWidth: '200px',
              padding: '12px 24px',
            },
            {
              width: '68px',
              maxWidth: '68px',
              minWidth: '68px',
              padding: '12px 24px',
            },
            {
              width: '68px',
              maxWidth: '68px',
              minWidth: '68px',
              padding: '12px 24px',
            },
          ]}
          /**
           * TABLE ROWS
           */
          dataRows={schedules.map((schedule) => {
            return {
              title: schedule.startTime!.toISOString(),
              id: schedule.id!,
              row: schedule,
              cells: [
                getScheduleDateRangeCell(schedule),
                getScheduleRecurrenceCell(schedule),
                getScheduleTimeCell(schedule),
                getScheduleDeleteCell(),
                getScheduleEditCell(),
              ],
            };
          })}
        />
      </Box>
    </>
  );
};

export default ScheduleWorkflow;
