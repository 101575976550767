import { Box, IconButton, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { EditableText } from './EditableText';
import { ReactComponent as BackArrowIcon } from '../../../static/icons/back-arrow.svg';
import CustomModal from '../../../components/CustomModal';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';

const FullPage = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  flex: 1,
}));

const BgBox = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  background: '#F7F8FA',
}));

const Page = styled(Box)(() => ({
  flex: 1,
  margin: '24px 60px',
  background: OrbyColorPalette['white-0'],
  borderRadius: '16px',
}));

const Header = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '48px 60px 0px 60px',
  background: OrbyColorPalette['white-0'],
  boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.04)',
}));

const LogoBox = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
}));

const Description = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  letterSpacing: 1,
}));

interface IProps {
  children: JSX.Element;
  title?: string;
  description?: string;
  // If true, the logo will be replaced with a back button
  enableLogoBack?: boolean;
  action?: JSX.Element;
  onTitleChange?: (newTitle: string) => void;
  hasUnsavedChanges?: boolean;
  onSave?: () => void;
  onDiscard?: () => void;
  headerChildren?: JSX.Element;
}

const Frame: React.FC<IProps> = ({
  title,
  description,
  children,
  onTitleChange,
  action,
  headerChildren,
  enableLogoBack,
  hasUnsavedChanges,
  onSave,
  onDiscard,
}) => {
  const [shouldShowUnsavedChanges, setShouldShowUnsavedChanges] =
    useState(false);

  const navigate = useNavigate();

  const back = () => {
    if (hasUnsavedChanges) {
      setShouldShowUnsavedChanges(true);
    } else {
      navigateBack();
    }
  };

  const navigateBack = () => {
    if (!history.state || history.state.idx === 0) {
      navigate('/');
    } else {
      navigate(-1);
    }
  };

  return (
    <FullPage>
      <Header>
        <Box display={'flex'} flexDirection={'row'} mb={'24px'}>
          <LogoBox>
            {enableLogoBack && (
              <IconButton onClick={back} aria-label='Go Back'>
                <BackArrowIcon />
              </IconButton>
            )}
            <Box flex={1} marginRight={6}>
              <EditableText
                disabled={!onTitleChange}
                fontSize={20}
                fontWeight={700}
                initialText={title || ''}
                onTextChange={onTitleChange}
                emptyErrorMessage={'Title cannot be empty'}
              />
              {description && <Description>{description}</Description>}
            </Box>
          </LogoBox>
          {action}
        </Box>
        {headerChildren}
      </Header>
      <BgBox>
        <Page>{children}</Page>
      </BgBox>
      <CustomModal
        open={shouldShowUnsavedChanges || false}
        heading='You have unsaved change'
        content={
          <OrbyTypography>Do you want to save your changes?</OrbyTypography>
        }
        handleClose={() => {
          setShouldShowUnsavedChanges(false);
        }}
        primaryLabel='Save'
        secondaryLabel='Discard'
        onPrimaryClick={() => {
          onSave?.();
          setShouldShowUnsavedChanges(false);
          navigateBack();
        }}
        onSecondaryClick={() => {
          onDiscard?.();
          setShouldShowUnsavedChanges(false);
          navigateBack();
        }}
      />
    </FullPage>
  );
};

export default Frame;
