import { Box, Chip, styled, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import {
  Workflow,
  WorkflowActionsForReview,
} from 'protos/pb/v1alpha1/orbot_workflow';
import { User } from 'protos/pb/v1alpha1/user';
import UserEmailAutocomplete from './UserEmailAutocomplete';
import React, { useEffect, useMemo } from 'react';
import {
  ActiveReviewModeSettingsOption,
  AssistModeSettingsOption,
} from './SettingsOption';
import { useDispatch, useSelector } from 'react-redux';
import { workflowAllDetailsSelector } from '../../../redux/selectors/workflow_details.selectors';
import { userDetailsAsArraySelector } from '../../../redux/selectors/filter_options.selectors';
import {
  addReviewer,
  removeReviewer,
  setActionForReview,
  setLowConfidenceThreshold,
} from '../../../redux/actions/workflow_details.constants';
import { getUserFiltersAction } from '../../../redux/actions/filter_options.action';
import { selectedOrgInfoSelector } from '../../../redux/selectors/user.selectors';

interface IWorkflowProps {
  workflow: Workflow | null;
}

const Title = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '28px',
  color: grey[900],
}));

const SubTitle = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  color: '#000',
}));

const P = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  color: '#3D4D6C',
}));

const Settings: React.FC<IWorkflowProps> = ({ workflow }) => {
  const dispatch = useDispatch();
  const userListStore = useSelector(userDetailsAsArraySelector);
  const workflowStore = useSelector(workflowAllDetailsSelector);

  // Getting the org id
  const orgInfo = useSelector(selectedOrgInfoSelector)!;

  const availableUsers = useMemo(
    () =>
      userListStore.filter(
        (user) => !workflow?.reviewerIds?.includes(user.id!) && user.activated,
      ),
    [userListStore.length, workflow?.reviewerIds],
  );

  // TODO: currently we only allow one setting for the repeated actionsForReview field. (this has not been implemented in extension as well)
  const actionsForReview =
    workflowStore?.workflow?.actionsForReview?.[0] ||
    WorkflowActionsForReview.LOW_CONFIDENCE_ACTIONS;

  const setActionsForReview = (actionsForReview: WorkflowActionsForReview) => {
    dispatch(setActionForReview([actionsForReview]));
  };

  useEffect(() => {
    dispatch(getUserFiltersAction(orgInfo.orgResourceName as string));
  }, [orgInfo.orgDisplayName]);

  return (
    <Box>
      <Box>
        <Title>Workflow review preferences</Title>
        <P>
          The workflow will only be visible to you. To make sure the automation
          works smoothly, please keep the browser open. Also, remember to
          prevent your computer from going to sleep, as this can stop the
          automation
        </P>
      </Box>
      <Box display='flex' flexDirection='column' mt={5} gap={4}>
        <Box display='flex' gap={15}>
          <Box display='flex' flexDirection='column' gap={4}>
            <SubTitle>Select how to review the work done by Orby</SubTitle>
            <ActiveReviewModeSettingsOption
              actionsForReview={actionsForReview}
              setActionsForReview={setActionsForReview}
            />
            <AssistModeSettingsOption
              actionsForReview={actionsForReview}
              setActionsForReview={setActionsForReview}
              lowConfidenceThreshold={workflow?.lowConfidenceThreshold}
              setLowConfidenceThreshold={(threshold) =>
                dispatch(setLowConfidenceThreshold(threshold))
              }
            />
          </Box>
          <Box display='flex' flexDirection='column' gap={4}>
            <SubTitle>Reviewers</SubTitle>
            <Box display='flex' flexDirection='column' gap={4} width={375}>
              <UserEmailAutocomplete
                onSelect={(user: User) => dispatch(addReviewer(user.id!))}
                users={availableUsers}
              />
              <Box display='flex' gap={2} flexWrap='wrap'>
                {userListStore.length > 0 &&
                  workflow?.reviewerIds?.map((userId) => (
                    <Chip
                      key={userId}
                      label={
                        userListStore.find((user) => user.id === userId)
                          ?.email || 'Unknown'
                      }
                      onDelete={() => dispatch(removeReviewer(userId!))}
                    />
                  ))}
              </Box>
            </Box>
          </Box>
        </Box>
        {/* currently the setting changes are auto-saved, need to sync with the design after POC */}
        {/*<Box display="flex" justifyContent="flex-end" mt={16} gap={3}>*/}
        {/*  <CancelButton variant="outlined">Cancel</CancelButton>*/}
        {/*  <SaveButton variant="contained">Save</SaveButton>*/}
        {/*</Box>*/}
      </Box>
    </Box>
  );
};

export default Settings;
