import { Box, TextField, Typography, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Action, WorkflowVariable } from 'protos/pb/v1alpha1/orbot_action';
import { getActions } from 'workflow-utils/src/v2/workflow';
import { Workflow as WorkflowObject } from 'protos/pb/v1alpha1/orbot_workflow';
import React from 'react';
import Process from './Process';

const ProcessDescription = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '24px',
  color: grey[900],
  marginBottom: theme.spacing(1),
}));

interface IWorkflowProps {
  workflow: WorkflowObject | null;
  isEditable: boolean;
  workflowVariables?: WorkflowVariable[];
  updateWorkflowVariable?: (key: string, value: string) => void;
}

const Workflow: React.FC<IWorkflowProps> = ({
  workflow,
  isEditable,
  workflowVariables,
  updateWorkflowVariable,
}) => {
  if (!workflow) {
    return null;
  }

  return (
    <Box>
      {workflow.processes?.map((process, index) => (
        <ProcessWithDescription
          key={index}
          description={process.description ?? 'Workflow steps'}
          workflowVariables={index === 0 ? workflowVariables : undefined}
          updateWorkflowVariable={
            index === 0 ? updateWorkflowVariable : undefined
          }
          actions={getActions(workflow, process.id) ?? []}
          isEditable={isEditable}
          processId={process.id}
        />
      ))}
    </Box>
  );
};

interface ProcessWithDescriptionProps {
  description: string;
  workflowVariables?: WorkflowVariable[];
  isEditable: boolean;
  updateWorkflowVariable?: (key: string, value: string) => void;
  actions: Action[];
  processId?: string;
}
const ProcessWithDescription: React.FC<ProcessWithDescriptionProps> = ({
  description,
  workflowVariables,
  isEditable,
  updateWorkflowVariable,
  actions,
  processId,
}) => {
  return (
    <>
      <ProcessDescription>{description}</ProcessDescription>
      {workflowVariables?.map(({ key, value }) => (
        <TextField
          key={key}
          value={value}
          label={key}
          variant='outlined'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            updateWorkflowVariable?.(key!, event.target.value);
          }}
          size='small'
          sx={{ width: '300px' }}
        />
      ))}
      <Process
        actions={actions}
        isEditable={isEditable}
        processId={processId}
      />
    </>
  );
};

export default Workflow;
