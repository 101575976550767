import { isFeatureFlagEnabled } from '../../pages/FeatureFlags/FeatureFlagUtils';
import { Document } from 'protos/google/cloud/documentai/v1/document';
import {
  GetTaskRequest,
  Task,
  UpdateTaskRequest,
} from 'protos/pb/v1alpha2/tasks_service';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ReviewTaskActionType } from '../actions/actions.constants';
import {
  getTaskForHitlCompletedAction,
  getTaskForHitlErrorAction,
  getTaskForReviewCompletedAction,
  getTaskForReviewErrorAction,
  setAutomationCompleted,
  setAutomationCompletedError,
} from '../actions/review_task.action';
import store from '../../redux/store';
import { gcsService } from '../../services/GcsService';
import { tasksV2Service } from '../../services/TasksV2Service';
import { FEATURE_FLAGS } from '../../utils/constants';
import { getSelectedTaskDocument } from '../../utils/helpers';
import { ExecutionStep } from 'protos/pb/v1alpha2/execution_steps';
import { SmartAction } from 'protos/pb/v1alpha1/actionprocessing';

export function* getTaskSaga(data: {
  type: ReviewTaskActionType;
  payload: GetTaskRequest;
  task?: Task;
}) {
  try {
    let responseTask, err;
    if (data.task) {
      responseTask = Task.fromPartial(data.task);
    } else {
      const { response, error }: { response?: Task; error?: Error } =
        yield call(tasksV2Service.getTask, data.payload);
      responseTask = response;
      err = error;
    }
    if (responseTask) {
      const task = responseTask;
      const documentStep = getSelectedTaskDocument(task);
      if (
        documentStep &&
        documentStep.documents &&
        documentStep.documents.length > 0 &&
        documentStep.documents[0].uri
      ) {
        const { response, error } = yield call(
          gcsService.getUriContent,
          documentStep.documents[0].uri,
        );
        if (error) {
          yield put(
            getTaskForReviewErrorAction(
              `Failed to fetch content for task: ${task.name}`,
            ),
          );
          return;
        } else {
          const textDoc = Document.decode(response);
          textDoc.uri = documentStep.documents[0].uri;
          documentStep.documents[0] = textDoc;
        }
      }
      const featureFlags =
        store?.getState()?.featureFlags?.featureFlagsForOrgAndUser;
      const showNewNestedUI = isFeatureFlagEnabled(
        FEATURE_FLAGS.NESTED_HITL,
        featureFlags,
      );
      yield put(
        getTaskForReviewCompletedAction(
          task,
          showNewNestedUI,
          data.task !== undefined,
        ),
      );
    } else {
      yield put(getTaskForReviewErrorAction(err));
    }
  } catch (error) {
    yield put(getTaskForReviewErrorAction(error as string));
  }
}

export function* getTaskAndSmatActionSaga(data: {
  type: ReviewTaskActionType;
  payload: GetTaskRequest;
}) {
  try {
    const { response, error }: { response?: Task; error?: Error } = yield call(
      tasksV2Service.getTaskSmartAction,
      data.payload,
    );
    const err = error;
    let smartAction: SmartAction;

    if (response) {
      const task = response?.executionSteps?.find((el: ExecutionStep) => {
        return el.options;
      });

      const action = task?.options?.find((option) => {
        return option.smartAction !== undefined;
      });

      if (action && action.smartAction) {
        smartAction = action.smartAction;
        yield put(
          getTaskForHitlCompletedAction({
            task: response,
            smartAction: smartAction,
          }),
        );
      }
    } else {
      yield put(getTaskForHitlErrorAction(err));
    }
  } catch (error) {
    yield put(getTaskForHitlErrorAction(error as string));
  }
}

export function* updateTaskSaga(data: {
  type: ReviewTaskActionType;
  payload: UpdateTaskRequest;
}): any {
  try {
    const { response, error } = yield call(
      tasksV2Service.updateTask,
      data.payload,
    );
    if (response) {
      yield put(setAutomationCompleted(true));
    } else {
      yield put(setAutomationCompletedError(error));
    }
  } catch (error) {
    yield put(setAutomationCompletedError(error as Error));
  }
}

function* reviewTaskSaga() {
  yield all([
    takeLatest(ReviewTaskActionType.GET_TASK_FOR_REVIEW, getTaskSaga),
    takeLatest(
      ReviewTaskActionType.GET_TASK_N_HITL_DATA,
      getTaskAndSmatActionSaga,
    ),
    takeLatest(ReviewTaskActionType.UPDATE_TASK, updateTaskSaga),
  ]);
}

export default reviewTaskSaga;
