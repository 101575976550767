import {
  Action,
  ActionGroup,
  ActionParamValue,
  PreparedAction,
  WorkflowVariable,
} from 'protos/pb/v1alpha1/orbot_action';
import {
  ActionGroupObject,
  PreparedActionObject,
  WorkflowObject,
} from './types';
import { Workflow, WorkflowProcess } from 'protos/pb/v1alpha1/orbot_workflow';

export function getActionGroups(workflow?: Workflow, processId?: string) {
  if (workflow?.processes && workflow?.processes?.length > 0) {
    // Default to first process if no process id is provided
    let process: WorkflowProcess | undefined = workflow?.processes[0];
    if (processId) {
      process = workflow?.processes.find((process) => process.id === processId);
    }
    return process?.generatedActionGroups;
  } else {
    return workflow?.taskExecution?.generatedActionGroups;
  }
}

export function getActionGroupById(
  id: string,
  actionGroups: ActionGroup[],
): ActionGroup | undefined {
  for (const actionGroup of actionGroups) {
    for (const preparedAction of actionGroup.preparedActions ?? []) {
      if (preparedAction.uuid === id) {
        return actionGroup;
      }
      if (preparedAction.conditionAction) {
        const trueBranch = getActionGroupById(
          id,
          preparedAction.conditionAction.trueActions ?? [],
        );
        if (trueBranch) {
          return trueBranch;
        }
        const falseBranch = getActionGroupById(
          id,
          preparedAction.conditionAction.falseActions ?? [],
        );
        if (falseBranch) {
          return falseBranch;
        }
      } else if (preparedAction.foreachAction) {
        const loop = getActionGroupById(
          id,
          preparedAction.foreachAction.actions ?? [],
        );
        if (loop) {
          return loop;
        }
      }
    }
  }

  return undefined;
}

export function addActionGroupAfter(
  newAction: ActionGroup,
  id: string,
  actionGroups: ActionGroup[],
): boolean {
  for (const [index, actionGroup] of actionGroups.entries()) {
    for (const preparedAction of actionGroup.preparedActions ?? []) {
      if (preparedAction.uuid === id) {
        actionGroups.splice(index + 1, 0, newAction);
        return true;
      }
      if (preparedAction.conditionAction) {
        if (
          addActionGroupAfter(
            newAction,
            id,
            preparedAction.conditionAction.trueActions ?? [],
          ) ||
          addActionGroupAfter(
            newAction,
            id,
            preparedAction.conditionAction.falseActions ?? [],
          )
        ) {
          return true;
        }
      }
      if (preparedAction.foreachAction) {
        if (
          addActionGroupAfter(
            newAction,
            id,
            preparedAction.foreachAction.actions ?? [],
          )
        ) {
          return true;
        }
      }
    }
  }
  return false;
}

export const allowAddingElseBlock = (
  actions: PreparedActionObject[],
): boolean => {
  // filter out all preparedActions that have conditionAction 'oneof' parameter
  const conditionActions = actions.filter((a) => a?.conditionAction);
  // if there is any conditionAction that has trueActions but no falseActions, we will allow the user to add else block
  for (const a of conditionActions) {
    if (
      (a.conditionAction!.trueActions ?? []).length > 0 &&
      (a.conditionAction?.falseActions ?? []).length === 0
    ) {
      return true;
    }
  }
  return false;
};

export const getWorkflowId = (workflow: WorkflowObject | Workflow) => {
  return workflow?.id || '';
};

export function getCreateTaskActionUUIDs(
  workflow: Workflow,
  processId?: string,
): string[] {
  const uuids: string[] = [];
  traverseActionGroupsRecursively(
    getActionGroups(workflow, processId),
    (actionGroup) => {
      actionGroup.preparedActions?.forEach((preparedAction) => {
        if (preparedAction.createTaskAction) {
          if (preparedAction.uuid) {
            uuids.push(preparedAction.uuid);
          }
        }
      });
    },
  );
  return uuids;
}

export function traverseActionGroupsRecursively(
  actionGroups: ActionGroupObject[] | undefined,
  callback: (actionGroup: ActionGroupObject) => void,
): void {
  if (!actionGroups) return;
  actionGroups.forEach((actionGroup) => {
    callback(actionGroup);
    actionGroup.preparedActions?.forEach((preparedAction) => {
      if (preparedAction.conditionAction) {
        traverseActionGroupsRecursively(
          preparedAction.conditionAction.trueActions,
          callback,
        );
        traverseActionGroupsRecursively(
          preparedAction.conditionAction.falseActions,
          callback,
        );
      } else if (preparedAction.foreachAction) {
        traverseActionGroupsRecursively(
          preparedAction.foreachAction.actions,
          callback,
        );
      }
    });
  });
}

export function getWorkflowVariables(
  workflow: Workflow | null,
  processId?: string,
): WorkflowVariable[] {
  const workflowVariables: WorkflowVariable[] = [];
  if (!workflow) {
    return workflowVariables;
  }

  traverseActionGroupsRecursively(
    getActionGroups(workflow, processId) || [],
    (action) => {
      action.preparedActions?.forEach((preparedAction) => {
        getParams(preparedAction).forEach((param) => {
          if (param?.envValue) {
            workflowVariables.push({ key: param.envValue, value: undefined });
          }
        });
      });
    },
  );
  return workflowVariables;
}

function getParams(action: PreparedAction): ActionParamValue[] {
  if (action.clickAction?.locator) {
    return [action.clickAction.locator];
  }
  if (action.getElementAction?.elementLocator) {
    return [action.getElementAction.elementLocator];
  }
  if (action.hoverAction?.locator) {
    return [action.hoverAction.locator];
  }
  if (action.conditionAction?.condition) {
    return [action.conditionAction.condition];
  }
  if (action.foreachAction?.items) {
    return [action.foreachAction.items];
  }
  if (action.createTaskAction?.workflowVariables) {
    return action.createTaskAction.workflowVariables;
  }
  if (action.customSmartAction?.inputs) {
    return Object.values(action.customSmartAction.inputs);
  }
  if (action.detectDuplicateLineItemsAction?.source) {
    return [action.detectDuplicateLineItemsAction.source];
  }
  if (action.extractFieldsAction?.document) {
    return [action.extractFieldsAction.document];
  }
  if (action.flagKeywordsAction?.source) {
    return [action.flagKeywordsAction.source];
  }
  if (action.generateTextAction?.inputs) {
    return action.generateTextAction.inputs;
  }
  if (action.gotoAction?.url) {
    return [action.gotoAction.url];
  }
  if (action.jsFunctionAction?.params) {
    return action.jsFunctionAction.params;
  }
  if (action.setValueAction) {
    return [
      action.setValueAction.fieldLocator!,
      action.setValueAction.fieldValue!,
    ];
  }
  if (action.validateAction) {
    return [action.validateAction.source!, action.validateAction.target!];
  }

  return [];
}
