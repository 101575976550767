import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { notification } from 'antd';
import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import CustomTypography from '../../components/CustomTypography';
import { useDispatch, useSelector } from 'react-redux';
import {
  createTaskAction,
  createTaskErrorAction,
} from '../../redux/actions/taskV2.action';
import { CreateTaskRequest } from 'protos/pb/v1alpha2/tasks_service';
import {
  taskCreationErrorSelector,
  addedTaskSelector,
  processingTaskSelector,
} from '../../redux/selectors/taskV2.selectors';
import CustomSearchSelect from '../../components/CustomSearchSelect';
import CustomPdfFilePicker from '../../components/CustomPdfFilePicker';
import CustomMultiTypeFilePicker from '../../components/CustomMultiTypeFilePicker';
import { selectedOrgInfoSelector } from '../../redux/selectors/user.selectors';
import { useFetchWorkflowFilters } from '../../hooks/useFetchWorkflowFilters';
import { File } from 'protos/automation_mining/ontology/data_models';
import {
  MAX_FILES_ALLOWED_TO_BE_UPLOADED_IN_BATCH,
  MAX_PROTO_SIZE_IN_BYTES,
} from '../../utils/constants';
import { OrbyButton } from 'orby-ui/src';
import { isFeatureFlagEnabled } from '../FeatureFlags/FeatureFlagUtils';
import { getFeatureFlagsForOrgAndUserSelector } from '../../redux/selectors/feature_flags.selectors';
import { FEATURE_FLAGS } from '../../utils/constants';

interface AddTaskModalProps {
  open: boolean;
  setOpen: (x: boolean) => void;
}

const AddTaskModal: FC<AddTaskModalProps> = ({ open, setOpen }) => {
  const [files, setFiles] = useState<File[]>([]);
  // Size of all files in bytes
  const [totalFilesSize, setTotalFilesSize] = useState(0);
  const [error, setError] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const [creationTriggered, setCreationTriggered] = useState(false);
  const taskCreationError = useSelector(taskCreationErrorSelector);
  const addingTask = useSelector(processingTaskSelector);
  const addedTask = useSelector(addedTaskSelector);
  const dispatch = useDispatch();
  const isMountedOnce = useRef(false);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const { workflowFilters, workflowFiltersLoading } = useFetchWorkflowFilters(
    selectedOrgInfo?.orgResourceName,
  );

  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const isSupportImageFileEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.SUPPORT_IMAGE_FILE,
    featureFlags,
  );

  const openError = (error: Error) => {
    api.info({
      message: 'Notification',
      description: error.message,
      placement: 'topRight',
      duration: null,
    });
  };

  const openSuccess = (msg: string) => {
    api.info({
      message: 'Success',
      description: msg,
      placement: 'topRight',
    });
  };

  useEffect(() => {
    if (taskCreationError) {
      openError(taskCreationError);
      dispatch(createTaskErrorAction(undefined));
      resetForm();
    }
  }, [taskCreationError]);

  useEffect(() => {
    if (creationTriggered && addedTask) {
      if (files.length === 1) {
        openSuccess('Task ' + (files[0].name ?? '') + ' is added successfully');
      } else {
        openSuccess(`${files?.length} tasks are added successfully`);
      }

      setTimeout(() => {
        handleModalClose();
      }, 1000);
    }
  }, [creationTriggered, addedTask]);

  useEffect(() => {
    return () => {
      setCreationTriggered(false);
    };
  }, []);

  useEffect(() => {
    if (open) {
      isMountedOnce.current = true;
    }
  }, [open]);

  const resetForm = () => {
    setFiles([]);
    setSelectedWorkflow('');
    setCreationTriggered(false);
  };

  const handleModalClose = () => {
    setOpen(false);
    setError(false);
    resetForm();
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          width: '480px',
          height: '560px',
          borderRadius: '12px',
        },
        id: 'add-task-modal',
        'aria-labelledby': 'add-task-modal',
      }}
      keepMounted={isMountedOnce.current}
      open={open}
      onClose={handleModalClose}
    >
      <DialogTitle
        padding={'24px !important'}
        sx={{
          height: '90px',
          backgroundColor: '#FFFFFF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <CustomTypography
          weight={600}
          sx={{
            textAlign: 'left',
            textJustify: 'center',
            color: '#101828',
          }}
          size={'20px'}
        >
          {'Add Task'}
        </CustomTypography>
        <IconButton
          aria-label='close'
          onClick={handleModalClose}
          sx={{ color: '#101828' }}
        >
          <CloseIcon fontSize={'medium'} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: '24px !important', pt: '0px !important' }}>
        {contextHolder}
        <CustomTypography weight={500} sx={{ paddingBottom: '8px' }}>
          1. Choose a Workflow
        </CustomTypography>
        <CustomSearchSelect
          options={workflowFilters}
          loading={workflowFiltersLoading}
          value={selectedWorkflow}
          placeholder='Please select'
          onChange={(value: string) => setSelectedWorkflow(value)}
        />
        <CustomTypography
          weight={500}
          sx={{ paddingBottom: '8px', paddingTop: '16px' }}
        >
          2. Upload files
        </CustomTypography>
        {isSupportImageFileEnabled ? (
          <CustomMultiTypeFilePicker
            files={files}
            setFiles={setFiles}
            workflowName={selectedWorkflow}
            error={error}
            setError={setError}
            isUploading={creationTriggered}
            setTotalFilesSize={setTotalFilesSize}
          />
        ) : (
          <CustomPdfFilePicker
            files={files}
            setFiles={setFiles}
            workflowName={selectedWorkflow}
            error={error}
            setError={setError}
            isUploading={creationTriggered}
            setTotalFilesSize={setTotalFilesSize}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ p: '24px !important', pt: '0px !important' }}>
        <OrbyButton
          variant='monochrome-outline'
          label='Cancel'
          onClick={handleModalClose}
        />
        <OrbyButton
          disabled={
            (files?.length ?? 0) === 0 || !selectedWorkflow || creationTriggered
          }
          variant='primary-contained'
          label={'Submit'}
          loading={addingTask}
          onClick={() => {
            if (files.length > MAX_FILES_ALLOWED_TO_BE_UPLOADED_IN_BATCH) {
              api.info({
                message: 'Notification',
                description: `You can upload maximum ${MAX_FILES_ALLOWED_TO_BE_UPLOADED_IN_BATCH} files at a time`,
                placement: 'topRight',
                duration: null,
              });
              return;
            }
            const req: CreateTaskRequest = {
              parent: selectedWorkflow,
              filesWrapper: { files: files },
            };
            if (totalFilesSize > MAX_PROTO_SIZE_IN_BYTES) {
              api.info({
                message: 'Notification',
                description: `You can send maximum ${MAX_PROTO_SIZE_IN_BYTES} bytes in a single request`,
                placement: 'topRight',
                duration: null,
              });
              return;
            }
            setCreationTriggered(true);
            dispatch(createTaskAction(req));
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default memo(AddTaskModal);
