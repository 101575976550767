import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  OrbyColorPalette,
  OrbyHeaderLabel,
  OrbyTable,
  OrbyTextField,
  OrbyTypography,
} from 'orby-ui/src';
import React, { useState } from 'react';
import CustomTooltip from './CustomTooltip';
import {
  getLineItemAmount,
  getLineItemDate,
  getLineItemDescription,
  getLineItemQuantity,
  getLineItemTax,
  getLineItemUnitCost,
  getPOlineNumber,
  getSNo,
  getTableColumnsForExtractedInvoice,
  getTableHeaderForExtarctedInvoice,
} from './HITLdetails-helper';
import { smartActions } from './smartActionRequest';
import { FieldGroup } from 'protos/pb/v1alpha1/field';
import { SmartAction } from 'protos/pb/v1alpha1/actionprocessing';

interface Props {
  isExpanded: boolean;
  data: SmartAction;
}

const HITLExtractedDataReview: React.FC<Props> = ({ isExpanded, data }) => {
  const [editableCell, setEditablCell] = useState(null);

  const handleCellClick = (index: any) => {
    setEditablCell(index);
  };

  const tableData = data ? data : smartActions;

  const caapsData: FieldGroup[] =
    tableData['reconcileLineItems']?.target?.fieldGroups || [];
  const jdeData: FieldGroup[] =
    tableData['reconcileLineItems']?.source?.fieldGroups || [];

  const [amounts, setAmounts] = useState(
    jdeData.map((item: any) => {
      return item.fields?.find((f: any) => f.name === 'line item/unit cost')
        ?.value?.text;
    }),
  );

  return (
    <Box
      id='data-review'
      display={'flex'}
      paddingTop={'24px'}
      paddingLeft={'60px'}
      paddingRight={'60px'}
      paddingBottom={'30px'}
      alignItems={'flex-start'}
      flexDirection={'column'}
    >
      <Box marginBottom={'16px'} display={'flex'} flexDirection={'row'}>
        <Box display={'flex'} flexDirection={'column'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            marginBottom={'16px'}
          >
            <OrbyTypography
              color={OrbyColorPalette['grey-900']}
              weight={'semibold'}
              size={'md'}
            >
              Extracted invoices from CAAPS
            </OrbyTypography>

            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              marginLeft={'12px'}
            >
              <FormControl>
                <RadioGroup
                  name='use-radio-group'
                  defaultValue='Show in right panel'
                >
                  <FormControlLabel
                    value='Show in right panel'
                    label={
                      <OrbyTypography
                        color={OrbyColorPalette['indigo-700']}
                        weight='semibold'
                        size='xs'
                      >
                        Show in right panel
                      </OrbyTypography>
                    }
                    control={<Radio size={'small'} />}
                    className='radio-label'
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>

          <Box width={isExpanded ? '662px' : '442px'}>
            <OrbyTable
              tableContainerSx={{
                borderRadius: 0,
                borderRight: 'unset',
              }}
              isDataLoading={false}
              tableWidth={442}
              noDataMessage={`No results for extracted invoices`}
              isCheckBoxDisabled={false}
              colSx={getTableColumnsForExtractedInvoice}
              headerColSx={getTableHeaderForExtarctedInvoice}
              rowSx={{
                '.visibility-off-icon': {
                  display: 'none',
                },
                '&:hover .visibility-off-icon': {
                  display: 'block',
                },
              }}
              tableId='extracted-invoices-table'
              showCheckBox={false}
              /**
               * TABLE HEADER ROWS
               */
              headerRows={[
                <OrbyHeaderLabel key={'extracted-invoice-sno'} label='' />,
                // <OrbyHeaderLabel
                //   key={'extracted-invoice-description'}
                //   label='Description'
                // />,
                <OrbyHeaderLabel
                  key={'extracted-invoice-quantity'}
                  label='Quantity'
                  sx={{
                    justifyContent: 'flex-end',
                  }}
                />,
                <OrbyHeaderLabel
                  key={'extracted-invoice-amount'}
                  label='Amount'
                  sx={{
                    justifyContent: 'flex-end',
                  }}
                />,
                <OrbyHeaderLabel
                  key={'extracted-invoice-tax-rate'}
                  label='Tax rate'
                  sx={{
                    justifyContent: 'flex-end',
                  }}
                />,
              ]}
              /**
               * TABLE ROWS
               */
              dataRows={caapsData.map((item, index) => {
                return {
                  title: 'item.fields',
                  id: `item.fields${index}`,
                  row: item.fields,
                  cells: [
                    getSNo(index),
                    getLineItemQuantity(item.fields),
                    getLineItemAmount(item.fields),
                    getLineItemTax(item.fields),
                  ],
                  isClickable: false,
                  isNoMatch: false,
                };
              })}
            />
          </Box>
        </Box>

        <Box display={'flex'} flexDirection={'column'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            marginBottom={'16px'}
          >
            <OrbyTypography
              color={OrbyColorPalette['grey-900']}
              weight={'semibold'}
              size={'md'}
              sx={{
                marginLeft: '24px',
              }}
            >
              Extracted PO from JDE
            </OrbyTypography>
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              marginLeft={'12px'}
            >
              <FormControl>
                <RadioGroup
                  name='use-radio-group'
                  // defaultValue='Extracted PO from JDE1'
                >
                  <FormControlLabel
                    value='Extracted PO from JDE1'
                    label={
                      <OrbyTypography
                        color={OrbyColorPalette['grey-700']}
                        weight='regular'
                        size='xs'
                      >
                        Show in right panel
                      </OrbyTypography>
                    }
                    control={<Radio size={'small'} />}
                    className='radio-label'
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>

          <Box width={'822px'}>
            <OrbyTable
              tableContainerSx={{
                borderRadius: 0,
              }}
              isDataLoading={false}
              tableWidth={822}
              noDataMessage={`No results for extracted invoices`}
              isCheckBoxDisabled={false}
              colSx={[
                {
                  width: `24px`,
                  maxWidth: `24px`,
                  minWidth: `24px`,
                  padding: '8px',
                  background: OrbyColorPalette['grey-50'],
                  borderBottom: `1px solid ${OrbyColorPalette['grey-200']}`,
                  borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
                },
                {
                  width: '108px',
                  maxWidth: '108px',
                  minWidth: '108px',
                  padding: '8px',
                  borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
                },
                {
                  width: '108px',
                  maxWidth: '108px',
                  minWidth: '108px',
                  padding: '0px',
                  borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
                },
                {
                  width: '108px',
                  maxWidth: '108px',
                  minWidth: '108px',
                  padding: '0px',
                  borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
                },
                {
                  width: '150px',
                  maxWidth: '150px',
                  minWidth: '150px',
                  padding: '8px',
                  borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
                },
                {
                  width: '108px',
                  maxWidth: '108px',
                  minWidth: '108px',
                  padding: '8px',
                  borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
                },
                {
                  width: '108px',
                  maxWidth: '108px',
                  minWidth: '108px',
                  padding: '8px',
                  borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
                },
                {
                  width: '108px',
                  maxWidth: '108px',
                  minWidth: '108px',
                  padding: '6px 10px',
                },
              ]}
              headerColSx={[
                {
                  width: `24px`,
                  maxWidth: `24px`,
                  minWidth: `24px`,
                  padding: '8px',
                  background: OrbyColorPalette['grey-50'],
                  borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
                },

                {
                  width: '108px',
                  maxWidth: '108px',
                  minWidth: '108px',
                  padding: '8px',
                  background: OrbyColorPalette['grey-50'],
                  borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
                },
                {
                  width: '108px',
                  maxWidth: '108px',
                  minWidth: '108px',
                  padding: '8px',
                  background: OrbyColorPalette['grey-50'],
                  borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
                },
                {
                  width: '108px',
                  maxWidth: '108px',
                  minWidth: '108px',
                  padding: '8px',
                  background: OrbyColorPalette['grey-50'],
                  borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
                },
                {
                  width: '150px',
                  maxWidth: '150px',
                  minWidth: '150px',
                  padding: '8px',
                  background: OrbyColorPalette['grey-50'],
                  borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
                },
                {
                  width: '108px',
                  maxWidth: '108px',
                  minWidth: '108px',
                  padding: '8px',
                  background: OrbyColorPalette['grey-50'],
                  borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
                },
                {
                  width: '108px',
                  maxWidth: '108px',
                  minWidth: '108px',
                  padding: '8px',
                  background: OrbyColorPalette['grey-50'],
                  borderRight: `1px solid ${OrbyColorPalette['grey-200']}`,
                },
                {
                  width: '108px',
                  maxWidth: '108px',
                  minWidth: '108px',
                  padding: '6px 10px',
                  background: OrbyColorPalette['grey-50'],
                },
              ]}
              rowSx={{
                '.visibility-off-icon': {
                  display: 'none',
                },
                '&:hover .visibility-off-icon': {
                  display: 'block',
                },
              }}
              tableId='extracted-invoices-table'
              showCheckBox={false}
              /**
               * TABLE HEADER ROWS
               */
              headerRows={[
                <OrbyHeaderLabel key={'extracted-po-sno'} label='' />,
                <OrbyHeaderLabel
                  key={'extracted-po-quantity'}
                  label='Quantity'
                  sx={{
                    justifyContent: 'flex-end',
                  }}
                />,
                <OrbyHeaderLabel
                  key={'extracted-po-unitcost'}
                  label='Unit cost'
                  sx={{
                    justifyContent: 'flex-end',
                  }}
                />,
                <OrbyHeaderLabel
                  key={'extracted-po-amount'}
                  label='Amount'
                  sx={{
                    justifyContent: 'flex-end',
                  }}
                />,
                <OrbyHeaderLabel
                  key={'extracted-po-description'}
                  label='Description'
                />,
                <OrbyHeaderLabel
                  key={'extracted-po-service-date'}
                  label='Service date'
                  sx={{
                    justifyContent: 'flex-end',
                  }}
                />,
                <OrbyHeaderLabel
                  key={'extracted-po-tax-rate'}
                  label='Tax rate'
                  sx={{
                    justifyContent: 'flex-end',
                  }}
                />,
                <OrbyHeaderLabel
                  key={'extracted-po-poline'}
                  label='PO line#'
                  sx={{
                    justifyContent: 'center',
                  }}
                />,
              ]}
              /**
               * TABLE ROWS
               */
              dataRows={jdeData.map((item, index) => {
                return {
                  title: 'item.fields',
                  id: `item.fields${index}`,
                  row: item.fields,
                  cells: [
                    getSNo(index, true),
                    getLineItemQuantity(item.fields),
                    getLineItemUnitCost(item.fields),
                    <div key={index}>
                      {editableCell === index ? (
                        <OrbyTextField
                          value={amounts[index]}
                          onChange={(e) => {
                            const newAmounts = [...amounts];
                            newAmounts[index] = e.target.value;
                            setAmounts(newAmounts);
                          }}
                          onBlur={() => setEditablCell(null)}
                          variant='flat'
                          width='100%'
                          autoFocus
                          inputSx={{
                            // height: '38px',
                            fontSize: '12px',
                            border: `1px solid ${OrbyColorPalette['blue-700']}`,
                            borderRadius: 0,
                            '& .MuiInputBase-input': {
                              textAlign: 'right',
                              // padding: '8px',
                            },
                          }}
                        />
                      ) : (
                        <CustomTooltip
                          title={
                            <Box padding={'12px'}>
                              <OrbyTypography
                                color={OrbyColorPalette['grey-700']}
                                size='xs'
                                weight='semibold'
                                sx={{
                                  marginBottom: '4px',
                                }}
                              >
                                Approximate match{' '}
                              </OrbyTypography>
                              <OrbyTypography
                                color={OrbyColorPalette['grey-500']}
                                size='xs'
                                weight='regular'
                              >
                                We’ve marked the invoice and PO as a “
                                <b>match</b>”, even though the <b>amounts</b>{' '}
                                differ. If this is incorrect, please adjust the
                                result in the last column.
                              </OrbyTypography>
                            </Box>
                          }
                        >
                          <Box
                            component='div'
                            padding={'8px'}
                            onDoubleClick={() => handleCellClick(index)}
                          >
                            {getLineItemAmount(item.fields)}
                          </Box>
                        </CustomTooltip>
                      )}
                    </div>,

                    // getLineItemAmount(item.fields),
                    getLineItemDescription(item.fields),
                    getLineItemDate(item.fields),

                    getLineItemTax(item?.fields),
                    getPOlineNumber('1', false),
                  ],
                  isClickable: false,
                  isNoMatch: false,
                };
              })}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HITLExtractedDataReview;
