import React from 'react';
import { Box, IconButton, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  OrbyBadge,
  OrbyButton,
  OrbyColorPalette,
  OrbyTypography,
} from 'orby-ui/src';
import { ReactComponent as BackArrowIcon } from '../../../static/icons/back-arrow.svg';

const Container = styled(Box)(() => ({
  display: 'flex',
  padding: '24px 60px 18px',
  alignItems: 'flex-start',
  flexDirection: 'column',
}));

const Header = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
}));

const TitleSection = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const BackButtonSection = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '16px',
  marginTop: '4px',
}));

const DescriptionBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  alignItems: 'center',
}));

const ActionButtonsBox = styled(Box)(() => ({
  display: 'flex',
  gap: '8px',
  marginBottom: '24px',
  marginLeft: '38px',
}));

const ExecutionDetailsBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '80px',
}));

const ExecutionInfo = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
}));

const Divider = styled(Box)(() => ({
  borderBottom: '1px solid',
  borderColor: OrbyColorPalette['grey-300'],
}));

interface Props {
  title: string;
  workflowName?: string;
  // data?: Task
}

const HITLTaskOverview: React.FC<Props> = ({ title, workflowName }) => {
  const navigate = useNavigate();

  const navigateBack = () => {
    !history.state ? navigate('/') : navigate(-1);
  };

  const getDescription = (workflowName: any) => (
    <OrbyBadge
      key={workflowName}
      size='large'
      badgeName={workflowName}
      backgroundColor={'#F2F4F7'}
      textColor={OrbyColorPalette['grey-700']}
      boxSx={{ borderRadius: '4px', paddingTop: '2px', paddingBottom: '2px' }}
    />
  );

  return (
    <>
      <Container>
        <Header>
          <TitleSection>
            <BackButtonSection>
              <IconButton
                onClick={navigateBack}
                aria-label='Go Back'
                sx={{ ml: '-12px' }}
              >
                <BackArrowIcon height='24px' width='24px' />
              </IconButton>
              <OrbyTypography
                color={OrbyColorPalette['grey-500']}
                weight='medium'
              >
                Back to all tasks
              </OrbyTypography>
            </BackButtonSection>

            <OrbyTypography
              size='display-xs'
              weight='semibold'
              sx={{ marginBottom: '12px' }}
            >
              {title}
            </OrbyTypography>

            <OrbyTypography color={OrbyColorPalette['grey-600']}>
              <DescriptionBox>{getDescription(workflowName)}</DescriptionBox>
            </OrbyTypography>
          </TitleSection>

          <Box display='flex' flexDirection='column'>
            <ActionButtonsBox>
              <OrbyButton
                size='large'
                variant='primary-contained'
                sx={{ width: '250px' }}
                label='Approve Invoice'
                ariaLabel='Approve Invoice'
              />
              <OrbyButton
                variant='primary-outline'
                sx={{ width: '250px' }}
                size='large'
                label='Reject Invoice'
                ariaLabel='Reject Invoice'
              />
            </ActionButtonsBox>

            <ExecutionDetailsBox>
              <ExecutionInfo>
                <Box display='flex' flexDirection='row' gap='12px'>
                  <OrbyTypography
                    size='xs'
                    color={OrbyColorPalette['grey-600']}
                  >
                    Execution Start Time
                  </OrbyTypography>
                  <OrbyTypography
                    size='xs'
                    color={OrbyColorPalette['grey-900']}
                    weight='medium'
                  >
                    {/* {data?.executionStartTime } */}
                    May 4, 2024 06:09 PST
                  </OrbyTypography>
                </Box>

                <Box display='flex' flexDirection='row' gap='17px'>
                  <OrbyTypography
                    size='xs'
                    color={OrbyColorPalette['grey-600']}
                  >
                    Execution End Time
                  </OrbyTypography>
                  <OrbyTypography
                    size='xs'
                    color={OrbyColorPalette['grey-900']}
                    weight='medium'
                  >
                    {/* {data?.executionEndTime || '-- (Paused)'} */}
                    -- (Paused)
                  </OrbyTypography>
                </Box>
              </ExecutionInfo>

              <ExecutionInfo>
                <Box display='flex' flexDirection='row' gap='12px'>
                  <OrbyTypography
                    size='xs'
                    color={OrbyColorPalette['grey-600']}
                  >
                    Time Paused
                  </OrbyTypography>
                  <OrbyTypography
                    size='xs'
                    color={OrbyColorPalette['grey-900']}
                    weight='medium'
                  >
                    {/* {data?.timePause} */}3 Hours
                  </OrbyTypography>
                </Box>

                <Box display='flex' flexDirection='row' gap='43px'>
                  <OrbyTypography
                    size='xs'
                    color={OrbyColorPalette['grey-600']}
                  >
                    Creator
                  </OrbyTypography>
                  <OrbyTypography
                    size='xs'
                    color={OrbyColorPalette['grey-900']}
                    weight='medium'
                  >
                    {/* {data?.creator} {data?.creatorEmail} */}
                    Jane Doe
                  </OrbyTypography>
                </Box>
              </ExecutionInfo>
            </ExecutionDetailsBox>
          </Box>
        </Header>
      </Container>

      <Divider />
    </>
  );
};

export default React.memo(HITLTaskOverview);
